/* .ac-main {
  color: white;
  font-size: 14px;
  width: 100%;
  max-width: 382px;
  margin-top: 10px;
} */
.ac-menu{
  position: absolute;
  top: 0;
  background-color: #1B1A1E;
  width: 100%;
  color: white;
}
.ac-menuCon{
  width: 100%;
  max-width: 400px;
  height: 55px;
  padding: 0 17px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.ac-menuLeft{
  display: flex;
  font-size: 13px;
  position: relative;
}

.ac-menuLine,.ex-menuLine{
  /* position: absolute;
  top:26px;  
  width: 27px; */
  height: 3px;
  margin-top: 6px;
background-image: linear-gradient(to right, #fd81e8, #f84da5);
}
.ac-menuLine{
  left: -1px;
}
.ex-menuLine{
  left: 50px;
}
.ac-select{
color:#B3B3B3;
margin-right: 24px;
}
.ac-selected{
  margin-right: 24px;
font-weight: bold;
}
.ac-lookRecord{
  display: flex;
  align-items: center;
  font-size: 12px;
}
.ac-lookRecord img{
  width: 17px;
  height: auto;
  margin-right: 5px;
}
.ac-changeChain {
  margin-top: 55px;
  display: flex;
  justify-content: space-between;
  position: relative;
  width: 100%;
  max-width: 400px;
  color: white;
  font-size: 14px;
}
.ac-changeChainIcon {
  width: 36px;
  height: 36px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -18px;
  margin-top: -18px;
}
.ac-fromTo {
  border: 1px solid #485053;
  border-radius: 8px;
  width: 49%;
  height: 86px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0 6px 0;
}
.ac-fromTo img {
  width: 22px;
  height: auto;
  border-radius: 11px;
}
.ac-transfer {
  width: 100%;
  max-width: 400px;
  margin: auto;
  font-size: 14px;
  color:white;
}
.ac-currency,
.ac-transferAmount,
.ac-wallet {
  border: 1px solid #485053;
  border-radius: 8px;
  height: 56px;
  padding: 2px 16px;
}
.ac-currency {
  margin-top: 16px;
  display: flex;
  align-items: center;
  /* //// */
  justify-content: space-between;
}
/* //// */
.ac-currency_box{
  display: flex;
  align-items: center;
}
.ac-currency img {
  width: 22px;
  height: 14px;
  margin-right: 12px;
}
.ac-transferAmount {
  margin-top: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.ac-transferAmount div {
  color: #e5cf80;
  border: 1px solid #e5cf80;
  border-radius: 4px;
  padding: 2px 8px;
}
.ac-wallet {
  margin-top: 16px;
  display: flex;
  align-items: center;
  padding: 2px 2px;
}
.ac-transferAmount input:focus,
.ac-wallet input:focus {
  outline: none;
}
.ac-transferAmount input,
.ac-wallet input {
  border: none;
  height: 40px;
  background-color: rgba(0, 0, 0, 0);
  color: white;
}
.ac-transferAmount input {
  width: 70%;
  font-size: 14px;
}
.ac-wallet input {
  width: 100%;
  font-size: 12px;
}
.ac-handlingFee,
.ac-get {
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
}
.ac-handlingFee div:nth-child(2),
.ac-get div:nth-child(2) {
  font-weight: bold;
}
.ac-get div:nth-child(2) {
  color: #f7479e;
}
.ac-transferButton {
  margin-top: 24px;
  background-image: linear-gradient(#fc7dcd, #f7479e);
  height: 48px;
  line-height: 48px;
  font-size: 16px;
  border-radius: 4px;
  text-align: center;
  font-weight: bold;
}

.r-record{
  color:white;
  font-size: 14px;
  width: 100%;
  max-width: 400px;
  margin: auto;
  margin-top: 10px;
}
.r-empty{
  padding: 16px 12px;
  font-weight: bold;
  color: #3f3f3f;
}
.r-list{
  border-bottom: 1px solid #24282A;
  margin-bottom: 16px;
}
.r-amount{
  font-size: 15px;
  font-weight: bold;
}
.r-title{
color: #706D70;
margin-top: 12px;
margin-bottom: 10px;
}
.r-data{
color:#999999;
margin-bottom: 19px;
}
.r-column{
  display: flex;
  justify-content: space-between;
}
.r-column div{
  width: 28%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.r-column div:nth-child(3){
  width: 40%;
  text-align: right;
}
.r-more{
  color: #f64da0;
  text-align: right;
  margin-top: 15px;
}